import React, { Component } from "react";
import { Route } from "react-router-dom";
import Menu from "../menu/menu";
import "./side.css";

class Side extends Component {
  constructor() {
    super();

    this.state = {
      menu: false
    };
  }

  showMenu = () => {
    this.setState({ menu: true });
  };
  hideMenu = () => {
    this.setState({ menu: false });
  };

  render() {
    return (
      <>
        <div className="sideSec">
          <div className="sideSecContainer">
            <div className="SideSecTop">
              {/**First Sec */}
              <div className="sideSecTopTitle">
                {/**THREE DOT MENu */}
                <div className="SideSecMenu">
                  <div className="sidemenuicn" onClick={this.showMenu}>
                    <div className="sidemenuIcnLines" />
                    <div className="sidemenuIcnLines" />
                    <div className="sidemenuIcnLines" />
                  </div>
                </div>
                {/**THREE DOT MENu */}

                {/**PAGE TITLE */}
                <div className="SideSecTitle">
                  {this.props.HomePage ? <p>nextname</p> : <p>My Wishlist</p>}
                </div>
                {/**PAGE TITLE */}
              </div>
              {/**First Sec */}

              {/**ACCOUNT SEC */}
              {/**ACCOUNT SEC */}
              <div className="sideSecTopAccount">
                {!this.props.logIn && (
                  <>
                    {/**LOG iN */}
                    <div className="LogInButtons">
                      <Route
                        render={({ history }) => (
                          <div
                            className="btns"
                            onClick={() => history.push("log-in")}
                          >
                            <p>Log In</p>
                          </div>
                        )}
                      />
                      <div className="btns">
                        <p>Sign Up</p>
                      </div>
                    </div>
                    {/**LOG iN */}
                  </>
                )}

                {this.props.logIn && (
                  <>
                    {/**ACCOUNT SEC*/}
                    <div className="UserInfo">
                      {/**AVTAR IMG */}
                      <div className="userAvtar">
                        <div className="AvtarImg">
                          <img
                            src={this.props.userInfo.abatarIMG}
                            alt="Avtar"
                          />
                          <div className="AvtarOverLay">
                            <div className="AvatarOverLayIcn" />
                          </div>
                        </div>
                      </div>
                      {/**AVTAR IMG */}

                      {/**OTHERS */}
                      <div className="userInfoOthers">
                        {/**username */}
                        <div className="uioTop">
                          <p>{this.props.userInfo.userName}</p>
                        </div>
                        {/**username */}
                        {/**links */}
                        <div className="uioDown">
                          {this.props.HomePage && (
                            <div
                              className="uioLinks"
                              onClick={this.props.hideHomePage}
                            >
                              <p>My Wishlist</p>
                            </div>
                          )}

                          {!this.props.HomePage && (
                            <div
                              className="uioLinks"
                              onClick={this.props.hideWishList}
                            >
                              <p>Home</p>
                            </div>
                          )}

                          {!this.props.pro && (
                            <div className="uioLinks">
                              <p>Upgrade</p>
                              <div className="externalLinkInc" />
                            </div>
                          )}
                          <div className="uioLinks">
                            <p>My profile</p>
                            <div className="externalLinkInc" />
                          </div>
                        </div>
                        {/**links */}
                      </div>
                      {/**OTHERS */}
                    </div>
                    {/**ACCOUNT SEC*/}
                  </>
                )}
              </div>
              {/**ACCOUNT SEC */}
              {/**ACCOUNT SEC */}
            </div>

            <div className="SideSecMiddle">
              <div className="nextpageDiv" onClick={this.props.pageChange}>
                <div className="nextPageIcn">
                  <div className="nextPageIcnImg" />
                </div>
                <div className="NextPageLabel">
                  <p>allnames</p>
                </div>
              </div>
            </div>

            <div className="SideSecbottom">
              <div className="scbContainer">
                <div className="scbContainer2">
                  {/*<div className="downloadLabel">
                    <div className="downloadIcn" />
                    <p>Download</p>
                          </div>*/}

                  <div className="downloadBtns">
                    <div className="SideBtns google" />
                    <div className="SideBtns apple" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.menu && (
          <Menu
            menu={this.state.menu}
            hideMenu={this.hideMenu}
            pro={this.props.pro}
          />
        )}
      </>
    );
  }
}

export default Side;
