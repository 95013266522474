import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Index from "./components/index/index";
import Mindex from "./mobile/index/m-index";
import LogIn from "./components/logIn/logIn";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { api } from "./conf";
import { toast } from "react-toastify";
import Msg from "./components/msg/msg";

import {
  BrowserView,
  MobileView,
  browserName,
  CustomView
} from "react-device-detect";

const instance = axios.create({
  baseURL: api,
  timeout: 5000,
  headers: {
    Authorization:
      localStorage.getItem("token") !== null
        ? "Bearer " + localStorage.getItem("token")
        : null
  }
});
class App extends Component {
  constructor() {
    super();

    this.state = {
      logIn: localStorage.getItem("token") === null ? false : true,
      pro: false,
      userInfo: {
        userName: "UserName",
        email: "emailid@email.com",
        abatarIMG: "file-loader!../../../images/avatar-min.jpg"
      },
      msg: "Welcome"
    };
  }

  componentDidMount() {
    instance.interceptors.response.use(undefined, error => {
      if (error.response && error.response.status === 401) {
        this.isLogin(false);
        localStorage.removeItem("token");
        error.message = "Security Token Expired, Please login Again";
        return Promise.reject(error);
      } else if (error.response && error.response.status === 403) {
        localStorage.removeItem("token");
        this.isLogin(false);
        error.message = "Failed to Retrive account details";
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });
    if (this.state.logIn) {
      this.loadProfile();
    }

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }
  loadProfile = () => {
    instance
      .get("/loadProfile")
      .then(resp => {
        if (resp.data.status) {
          this.setUserInfo({
            userName: resp.data.profile.username,
            email: resp.data.profile.email,
            abatarIMG: resp.data.profile.dp
          });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };
  isLogin = value => {
    this.setState({ logIn: value });
    if (value === true) {
      instance.defaults.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    } else {
      instance.defaults.headers["Authorization"] = null;
    }
  };
  isPro = value => {
    this.setState({ pro: value });
  };
  setUserInfo = user => {
    this.setState({ userInfo: user });
  };

  componentDidUpdate() {
    let img = "./images/logo.png";
    document
      .querySelector('link[rel="shortcut icon"]')
      .setAttribute("href", img);

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }
  render() {
    return (
      <>
        {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}  msg={this.state.msg} />}

        <BrowserView>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Index
                    page={this.state.page}
                    logIn={this.state.logIn}
                    pro={this.state.pro}
                    userInfo={this.state.userInfo}
                    axios={instance}
                  />
                )}
                exact
              />

              <LoginRoute
                exact
                path="/log-in"
                auth={this.state.logIn}
                component={() => (
                  <LogIn
                    page={this.state.page}
                    logIn={this.state.logIn}
                    pro={this.state.pro}
                    userInfo={this.state.userInfo}
                    isLogin={this.isLogin}
                    setUserInfo={this.setUserInfo}
                    axios={instance}
                  />
                )}
              />
            </Switch>
          </BrowserRouter>
        </BrowserView>

        <MobileView>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Mindex
                    page={this.state.page}
                    logIn={this.state.logIn}
                    isLogin={this.isLogin}
                    setUserInfo={this.setUserInfo}
                    pro={this.state.pro}
                    userInfo={this.state.userInfo}
                    axios={instance}
                  />
                )}
                exact
              />
            </Switch>
          </BrowserRouter>
        </MobileView>
      </>
    );
  }
}
const LoginRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !auth ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
