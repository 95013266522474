import React, { Component } from "react";
import "./m-profile.css";
import Mlogin from "./m-login";
import Wishlist from "../../components/wishlist/wishList";

class Mprofile extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="m-profile">
          <div className="m-profileContainer">
            {this.props.page === 1 &&
              (this.props.logIn ? (
                <Wishlist
                  axios={this.props.axios}
                  logIn={this.props.logIn}
                  isLogin={this.props.isLogin}
                  setUserInfo={this.props.setUserInfo}
                  mobile={true}
                />
              ) : (
                <Mlogin
                  axios={this.props.axios}
                  logIn={this.props.logIn}
                  isLogin={this.props.isLogin}
                  setUserInfo={this.props.setUserInfo}
                />
              ))}
          </div>
        </div>
      </>
    );
  }
}

export default Mprofile;
