import React, { Component } from "react";
import "./home.css";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollToFetch from "react-scroll-to-fetch";
import NameCard from "../../components/namecard/namecard";
import LoadCard from "../../components/namecard/nameLoad";
import Msg from "../../components/msg/msg";

class Mhome extends Component {
  constructor() {
    super();

    this.state = {
      page: 2,
      searchTxt: "",
      searchGender: "",
      suggestions: [],
      hasMore: true,
      msg: "",
      name: "A Name",
      des: "The Meaning",
      loadingRandomBtn: false,
      hasMore: true,
      speak: false,
      loaded: true,
      religion: "No Religion",
      religions: [
        { id: 1, religion: "All Religion" },
        // { id: 2, religion: "Christianity" },
        { id: 3, religion: "Islam" },
        { id: 4, religion: "Hinduism" }
        // { id: 5, religion: "Buddhism" },
        // { id: 6, religion: "Sikhism" },
        // { id: 7, religion: "Judaism" }
      ],
      //TODAY'S PICK
      nameId: "",
      gender: "Gender",
      inWishList: false,
      name: "A Name",
      des: "The Meaning"
    };
  }
  addToWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/addWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState({ inWishList: true });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  addToWishListSug = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/addWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ suggestions }) => {
              var newList = suggestions.map((v, i) => {
                if (v._id === id) {
                  v.wishList = true;
                  return v;
                }
                return v;
              });
              return { suggestions: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  removeFromWishListSug = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ suggestions }) => {
              var newList = suggestions.map((v, i) => {
                if (v._id === id) {
                  v.wishList = false;
                  return v;
                }
                return v;
              });
              return { suggestions: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  removeFromWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState({ inWishList: false });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({
        msg: "You Need To Login to remove a Name from wishList"
      });
      //toast.error("You Need To Login to remove a Name from wishList");
    }
  };

  componentDidMount() {
    this.givingMinimumWeight();
    this.todaysPic();
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  componentDidUpdate() {
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  givingMinimumWeight = () => {
    /*const height = window.innerHeight;
    document.body.style.minHeight = width;
    document.getElementsByTagName("body").style.minHeight = height;*/
    setTimeout(function() {
      let viewheight = window.outerHeight;
      let viewwidth = window.outerWidth;
      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute(
        "content",
        "height=" +
          viewheight +
          "px, width=" +
          viewwidth +
          "px, initial-scale=1.0"
      );
    }, 300);
  };

  changeText = e => {
    this.setState(
      { searchTxt: e.target.value, hasMore: true, suggestions: [] },
      () => {
        this.search(1);
      }
    );
  };

  clearSearch = () => {
    this.state.searchTxt.length > 0 && this.setState({ searchTxt: "" });
  };

  dropDownToggle = () => {
    document.getElementById("dropDownIcn").classList.toggle("select");
    document.getElementById("dropdownMenu").classList.toggle("hide");
    document.getElementById("rndBtn").classList.toggle("fade");
    document.getElementById("gndBtn").classList.toggle("fade");
  };
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */

  HandleGenderClick = gender => {
    if (gender === "boy") {
      document.getElementById("boy").classList.toggle("selected");
      this.state.boy
        ? this.setState({ boy: false })
        : this.setState({ boy: true });
    }

    if (gender === "girl") {
      document.getElementById("girl").classList.toggle("selected");
      this.state.girl
        ? this.setState({ girl: false })
        : this.setState({ girl: true });
    }
  };

  searchCond = sex => {
    this.setState({ hasMore: true, suggestions: [], searchGender: sex }, () => {
      this.search(1);
    });
  };

  search = async page => {
    const query = this.state.searchTxt;
    const gender = this.state.searchGender;
    try {
      const resp = await this.props.axios.post("/search", {
        query,
        page,
        gender
      });
      if (resp.data.status) {
        this.setState(state => {
          var suggestions = [...state.suggestions, ...resp.data.list];
          return { suggestions };
        });

        if (resp.data.list.length < 20) {
          this.setState({ hasMore: false });
        }
      } else {
        this.setState({ hasMore: false });
      }
    } catch (err) {
      this.setState({ msg: err.message });
      //toast.error(err.message);
    }
  };

  changeReligion = selected => {
    this.setState({
      religion: selected
    });
  };

  todaysPic = () => {
    const { axios } = this.props;
    // document.getElementById("tpLabel").classList.add("fade");
    this.setState({ loaded: false, loadingRandomBtn: true });
    axios
      .get("/todaysPick")
      .then(resp => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        if (resp.data.status) {
          this.setState({
            nameId: resp.data.result.nameId._id,
            name: resp.data.result.nameId.name,
            des: resp.data.result.nameId.meaning,
            rel: resp.data.result.nameId.rel,
            gender: resp.data.result.nameId.sex,
            inWishList: resp.data.WishList
          });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        // console.log(err);
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };
  randomBtnHandler = () => {
    const { axios } = this.props;
    const { boy, girl, religion } = this.state;
    // document.getElementById("tpLabel").classList.add("fade");
    this.setState({ loaded: false, loadingRandomBtn: true });
    axios
      .post("/random", {
        boy: boy,
        girl: girl,
        religion
      })
      .then(resp => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        if (resp.data.status) {
          this.setState({
            nameId: resp.data.result._id,
            name: resp.data.result.name,
            des: resp.data.result.meaning,
            rel: resp.data.result.rel,
            gender: resp.data.result.sex,
            inWishList: resp.data.WishList
          });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        // console.log(err);
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };

  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  /**FUNCTIONS */
  render() {
    return (
      <>
        {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}  msg={this.state.msg} />}
        <div className="m-Home">
          {/**PART ONE */}
          {/**PART ONE */}
          <div className="m-SearchBox">
            <div className="m-searchBar">
              <input
                type="text"
                onChange={this.changeText}
                value={this.state.searchTxt}
              />
              <div
                className={
                  this.state.searchTxt.length > 0
                    ? "m-searchInc close"
                    : "m-searchInc"
                }
                onClick={this.clearSearch}
              >
                <div />
              </div>
            </div>
            <div className="m-WhiteLine" />

            <p
              className={
                this.state.searchTxt.length > 0 ? "m-sText active" : "m-sText"
              }
              id="searchText"
            >
              Search a name
            </p>
          </div>
          {/**PART ONE */}
          {/**PART ONE */}

          {/**PART TWO IF SEARCHED */}
          {/**PART TWO IF SEARCHED */}

          {this.state.searchTxt.length > 0 && (
            <>
              <div className="m-searchResults">
                <div className="m-SearchResultsHomeContainer">
                  <div className="m-srhFilters">
                    <p
                      onClick={() => this.searchCond("")}
                      className={
                        this.state.searchGender === "" ? "s_active" : ""
                      }
                    >
                      Search all name with "{this.state.searchTxt}"
                    </p>
                    {this.state.searchTxt.trim().length >= 2 && (
                      <>
                        <p
                          onClick={() => this.searchCond("Boy")}
                          className={
                            this.state.searchGender === "Boy" ? "s_active" : ""
                          }
                        >
                          Search all boy's name with "{this.state.searchTxt}"
                        </p>
                        <p
                          onClick={() => this.searchCond("Girl")}
                          className={
                            this.state.searchGender === "Girl" ? "s_active" : ""
                          }
                        >
                          Search all girl's name with "{this.state.searchTxt}"
                        </p>
                      </>
                    )}
                  </div>

                  <div className="m-srhSuggestions">
                    <div className="srhSuggContainer">
                      <Scrollbars
                        style={{ width: "100%", height: "100%" }}
                        renderTrackVertical={props => (
                          <div {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={props => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        universal={true}
                        id="m_search_results"
                      >
                        {/**SUGGESTIONS */}
                        {/**SUGGESTIONS */}
                        {/**SUGGESTIONS */}
                        <ScrollToFetch
                          loader={
                            <>
                              <div className="m-nameCardContainer">
                                <LoadCard />
                              </div>
                            </>
                          }
                          fetch={this.search}
                          finished={!this.state.hasMore}
                          className="srchContainerSuggest"
                          scrollParent="m_search_results"
                          initialLoad={false}
                          successMessage={
                            this.state.suggestions.length === 0 ? (
                              <p className="no_result">No results found</p>
                            ) : (
                              <p></p>
                            )
                          }
                        >
                          {this.state.suggestions.map(suggest => (
                            <div
                              className="m-nameCardContainer"
                              key={suggest._id}
                            >
                              {/**CARD */}
                              {/**CARD */}

                              <NameCard
                                id={suggest._id}
                                name={suggest.name}
                                gender={suggest.sex}
                                des={suggest.meaning}
                                addToWishList={this.addToWishListSug}
                                removeFromWishList={this.removeFromWishListSug}
                                wishlist={suggest.wishList}
                                rel={suggest.rel}
                              />

                              {/**CARD */}
                              {/**CARD */}
                            </div>
                          ))}
                        </ScrollToFetch>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/**PART TWO IF SEARCHED */}
          {/**PART TWO IF SEARCHED */}

          {/**PART TWO IF NOT SEARCHED */}
          {/**PART TWO IF NOT SEARCHED */}
          {/**PART TWO IF NOT SEARCHED */}
          {this.state.searchTxt.length === 0 && (
            <>
              <div className="m-hpPartTwo">
                {/**RANDOM BTNS */}
                {/**RANDOM BTNS */}
                <div className="m-randomBtnSec">
                  {/**religion */}
                  {/**religion */}
                  {/**religion */}

                  <div className="m-religion">
                    <div className="religionTab" onClick={this.dropDownToggle}>
                      <div className="selectionReligion">
                        <p>{this.state.religion}</p>
                        <div className="dropdownIcn" id="dropDownIcn" />
                      </div>
                      <div
                        className="m-religionDropDown  hide"
                        id="dropdownMenu"
                      >
                        <Scrollbars
                          style={{ width: "100%", height: "100%" }}
                          renderTrackVertical={props => (
                            <div {...props} className="track-vertical" />
                          )}
                          renderThumbVertical={props => (
                            <div {...props} className="thumb-vertical" />
                          )}
                          autoHide
                          autoHideTimeout={1000}
                          autoHideDuration={200}
                          universal={true}
                        >
                          <div className="m-rgDropDownContainer">
                            {this.state.religions.map(religion => (
                              <div
                                key={religion.id}
                                className="DDItem"
                                id={religion.id}
                                onClick={() =>
                                  this.changeReligion(religion.religion)
                                }
                              >
                                <p>{religion.religion}</p>
                              </div>
                            ))}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                  </div>
                  {/**religion */}

                  <div className="m-genderBtns" id="gndBtn">
                    <div
                      className="m-genderBtn"
                      id="boy"
                      onClick={() => this.HandleGenderClick("boy")}
                    >
                      <p>Boy</p>
                    </div>
                    <div
                      className="m-genderBtn"
                      id="girl"
                      onClick={() => this.HandleGenderClick("girl")}
                    >
                      <p>Girl</p>
                    </div>
                  </div>

                  <div className="m-randomBtnBig" id="rndBtn">
                    <p onClick={this.randomBtnHandler}>{this.state.loadingRandomBtn ? "..." : "Random"}</p>
                  </div>
                </div>
                {/**RANDOM BTNS */}
                {/**RANDOM BTNS */}

                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                <div className="m-homePageNCcontainer">
                  {/**CARD */}
                  {/**CARD */}

                  <NameCard
                    id={this.state.nameId}
                    name={this.state.name}
                    gender={this.state.gender}
                    des={this.des}
                    addToWishList={this.addToWishList}
                    removeFromWishList={this.removeFromWishList}
                    wishlist={this.state.inWishList}
                    rel={this.state.rel}
                  />

                  {/**CARD */}
                  {/**CARD */}
                </div>
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
              </div>
            </>
          )}

          {/**PART TWO IF NOT SEARCHED */}
          {/**PART TWO IF NOT SEARCHED */}
          {/**PART TWO IF NOT SEARCHED */}
        </div>
      </>
    );
  }
}

export default Mhome;
