import React, { Component } from "react";
import "./m-allnames.css";
import NameCard from "../../components/namecard/namecard";
import { Scrollbars } from "react-custom-scrollbars";
import LoadCard from "../../components/namecard/nameLoad";
import ScrollToFetch from "react-scroll-to-fetch";
import { SpringSystem } from "rebound";
import Msg from "../../components/msg/msg";
var p = new Array(26);
p.fill(0);
class MallNames extends Component {
  constructor() {
    super();
    this.state = {
      hasMore: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],Names: [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
      ],
      alphabet: [
        { id: 1, value: "A" },
        { id: 2, value: "B" },
        { id: 3, value: "C" },
        { id: 4, value: "D" },
        { id: 5, value: "E" },
        { id: 6, value: "F" },
        { id: 7, value: "G" },
        { id: 8, value: "H" },
        { id: 9, value: "I" },
        { id: 10, value: "J" },
        { id: 11, value: "K" },
        { id: 12, value: "L" },
        { id: 13, value: "M" },
        { id: 14, value: "N" },
        { id: 15, value: "O" },
        { id: 16, value: "P" },
        { id: 17, value: "Q" },
        { id: 18, value: "R" },
        { id: 19, value: "S" },
        { id: 20, value: "T" },
        { id: 21, value: "U" },
        { id: 22, value: "V" },
        { id: 23, value: "W" },
        { id: 24, value: "X" },
        { id: 25, value: "Y" },
        { id: 26, value: "Z" }
      ],
      page: p,
      current: "A",
      alphaId: 1,
      msg: ""
    };
  }
  componentDidMount() {
    // console.log(this.state);
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
    this.search(1);
    this.refs.scrollbars.scrollToTop();
  }
  componentDidUpdate(){
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }
  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }
  getScrollTop() {
    return this.refs.scrollbars.getScrollTop();
  }

  getScrollHeight() {
    return this.refs.scrollbars.getScrollHeight();
  }

  getHeight() {
    return this.refs.scrollbars.getHeight();
  }

  scrollTop(top) {
    const { scrollbars } = this.refs;
    const scrollTop = scrollbars.getScrollTop();
    const scrollHeight = scrollbars.getScrollHeight();
    // const val = MathUtil.mapValueInRange(top, 0, scrollHeight, scrollHeight * 0.2, scrollHeight * 0.8);
    this.spring.setCurrentValue(scrollTop).setAtRest();
    this.spring.setEndValue(top - 170);
  }

  handleSpringUpdate=(spring)=> {
    const { scrollbars } = this.refs;
    const val = spring.getCurrentValue();
    scrollbars.scrollTop(val);
  }
  handleClick = id => {
    let pos = document.getElementById("grid_" + id).getBoundingClientRect().top;
    let top = document.getElementById("grid_1").getBoundingClientRect().top;
    let destination = pos - top;

    // console.log('serach');
    this.setState(
      ({ alphabet }) => {
        return { alphaId: id, current: alphabet[id - 1].value };
      },
      () => {
        this.setState(
          ({ hasMore }) => {
            let tmp = hasMore.map((v, i) => {
              if (i === id - 1) {
                return true;
              }
              return false;
            });
            return { hasMore: tmp };
          },
          () => {
            this.search(1);
          }
        );
      }
    );
    this.scrollTop(destination);
  };
  search = async page => {
    var alphabet = this.state.current;
    var alphaId = this.state.alphaId;
    this.setState(({ page }) => {
      var np = page.map((p, i) => {
        if (i === alphaId - 1) {
          return p + 1;
        }
        return p;
      });
      return { page: np };
    }, console.log(page));
    try {
      const resp = await this.props.axios.post("/allnames", { alphabet, page });
      if (resp.data.status) {
        this.setState(({ Names }) => {
          let newlist = Names.map((Name, i) => {
            if (i === alphaId - 1) {
              return [...Name, ...resp.data.list];
            }
            return Name;
          });
          return { Names: newlist };
        });
        if (resp.data.list.length < 20) {
          this.setState(
            ({ hasMore }) => {
              let tmp = hasMore.map((v, i) => {
                if (i === alphaId - 1) {
                  return false;
                }
                return false;
              });
              return { hasMore: tmp };
            },
            () => {
              //load the next next alphabet
              var id = alphaId;
              this.setState(
                ({ alphabet }) => {
                  return { alphaId: id + 1, current: alphabet[id].value };
                },
                () => {
                  this.setState(
                    ({ hasMore, alphaId }) => {
                      let tmp = hasMore.map((v, i) => {
                        if (i === alphaId - 1) {
                          return true;
                        }
                        return false;
                      });
                      return { hasMore: tmp };
                    },
                    () => {
                      this.search(1);
                    }
                  );
                }
              );
            }
          );
        }
      } else {
        this.setState(
          ({ hasMore }) => {
            let tmp = hasMore.map((v, i) => {
              if (i === alphaId - 1) {
                return false;
              }
              return false;
            });
            return { hasMore: tmp };
          },
          () => {
            //load the next next alphabet
            var id = alphaId;
            this.setState(
              ({ alphabet }) => {
                return { alphaId: id + 1, current: alphabet[id].value };
              },
              () => {
                this.setState(
                  ({ hasMore, alphaId }) => {
                    let tmp = hasMore.map((v, i) => {
                      if (i === alphaId - 1) {
                        return true;
                      }
                      return false;
                    });
                    return { hasMore: tmp };
                  },
                  () => {
                    this.search(1);
                  }
                );
              }
            );
          }
        );
      }
    } catch (err) {
      this.setState({ msg: err.message });
      //toast.error(err.message);
    }
    //console.log(this.state);
  };
  addToWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/addWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ Names, alphaId }) => {
              var newList = Names.map((name, i) => {
                if (i === alphaId - 1) {
                  var ls = name.map((v, i) => {
                    if (v._id === id) {
                      v.wishList = true;
                      return v;
                    }
                    return v;
                  });
                  return ls;
                } else {
                  return name;
                }
              });
              return { Names: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  removeFromWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ Names, alphaId }) => {
              var newList = Names.map((name, i) => {
                if (i === alphaId - 1) {
                  var ls = name.map((v, i) => {
                    if (v._id === id) {
                      v.wishList = false;
                      return v;
                    }
                    return v;
                  });
                  return ls;
                } else {
                  return name;
                }
              });
              return { Names: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({
        msg: "You Need To Login to remove a Name from wishList"
      });
      //toast.error("You Need To Login to remove a Name from wishList");
    }
  };
  render() {
    return (
      <>
      {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}  msg={this.state.msg} />}
        <div className="m-allnames">
          <div className="m-anContainer">
            {/**ALLNAMES */}
            {/**ALLNAMES */}
            <div className="m-alNames">
              <Scrollbars
                ref="scrollbars"
                style={{ width: "100%", height: "100%" }}
                renderTrackVertical={props => (
                  <div {...props} className="track-vertical" />
                )}
                renderThumbVertical={props => (
                  <div {...props} className="thumb-vertical" />
                )}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                universal={true}
                id="m_allnames_scroll"
              >
                {this.props.page === 3 &&
                  this.state.alphabet.map(namesLst => (
                    <div
                      className="NamesGrid"
                      key={namesLst.id}
                      id={"grid_" + namesLst.id}
                    >
                      <p>Name Starts With "{namesLst.value}"</p>


                      <ScrollToFetch
                      className="NamesWithContainer"
                      loader={
                        <div className="m-allNamesNameCard">
                          <LoadCard />
                        </div>
                      }
                      fetch={this.search}
                      currentPage={this.state.page[namesLst.id - 1]}
                      finished={!this.state.hasMore[namesLst.id - 1]}
                      scrollParent="m_allnames_scroll"
                      initialLoad={false}
                      successMessage={
                        this.state.Names[namesLst.id - 1] &&
                        this.state.Names[namesLst.id - 1].length > 0 ? (
                          <p />
                        ) : (
                          <div className="m-allNamesNameCard">
                            <LoadCard />
                          </div>
                        )
                      }
                    >
                      {this.state.Names[namesLst.id - 1] &&
                        this.state.Names[namesLst.id - 1].map(Names => (
                          <div className="m-allNamesNameCard" key={Names._id}>
                            {/**CARD */}
                            {/**CARD */}

                            <NameCard
                              id={Names._id}
                              name={Names.name}
                              gender={Names.sex}
                              des={Names.meaning}
                              wishlist={Names.wishList}
                              addToWishList={this.addToWishList}
                              removeFromWishList={this.removeFromWishList}
                              rel={Names.rel}
                              religion={true}
                            />

                            {/**CARD */}
                            {/**CARD */}
                          </div>
                        ))}
                    </ScrollToFetch>
                    </div>
                  ))}
              </Scrollbars>
            </div>
            {/**ALLNAMES */}
            {/**ALLNAMES */}

            {/**ALPHABETS */}
            {/**ALPHABETS */}
            <div className="m-alphabets">
              {this.state.alphabet.map(aplh => (
                <p onClick={(e)=>{e.preventDefault();this.handleClick(aplh.id)}} key={aplh.id}>{aplh.value}</p>
              ))}
            </div>
            {/**ALPHABETS */}
            {/**ALPHABETS */}
          </div>
        </div>
      </>
    );
  }
}

export default MallNames;
