import React, { Component } from "react";
import "./m-index.css";
import Background from "../../components/bg/bg";
import Mfooter from "../footer/m-footer";
import Mhome from "../home/home";
import MallNames from "../allnames/m-allnames";
import Mprofile from "../m-profile/m-profile";

import { browserName, CustomView } from "react-device-detect";

class Mindex extends Component {
  constructor() {
    super();

    this.state = {
      page: 2
    };
  }

  componentDidUpdate() {
    let slider = document.getElementById("slider");
    this.state.page === 1 && slider.classList.remove("right");
    this.state.page === 1 && slider.classList.add("left");
    this.state.page === 2 && slider.classList.remove("right");
    this.state.page === 2 && slider.classList.remove("left");
    this.state.page === 3 && slider.classList.add("right");
  }

  changePage = e => {
    this.setState({ page: e });
  };

  render() {
    return (
      <>
        <CustomView condition={browserName !== "Chrome"}>
          <div className="m-container" id="mContain">
            <Background />

            {/**SLIDER */}
            {/**SLIDER */}
            {/**SLIDER */}
            <div className="m-indeSlider">
              <div className="m-isContainer" id="slider">
                {/**PAGE */}
                <div className="m-isPage">
                  <Mprofile
                    page={this.state.page}
                    axios={this.props.axios}
                    logIn={this.props.logIn}
                    isLogin={this.props.isLogin}
                    setUserInfo={this.props.setUserInfo}
                  />
                </div>
                {/**PAGE */}

                {/**PAGE */}
                <div className="m-isPage">
                  <Mhome axios={this.props.axios} logIn={this.props.logIn} />
                </div>
                {/**PAGE */}

                {/**PAGE */}
                <div className="m-isPage">
                  <MallNames
                    page={this.state.page}
                    axios={this.props.axios}
                    logIn={this.props.logIn}
                  />
                </div>
                {/**PAGE */}
              </div>
            </div>
            {/**SLIDER */}
            {/**SLIDER */}
            {/**SLIDER */}

            <Mfooter
              changePage={this.changePage}
              page={this.state.page}
              axios={this.props.axios}
              logIn={this.props.logIn}
            />
          </div>
        </CustomView>

        {/**FOR CHROME */}
        {/**FOR CHROME */}
        {/**FOR CHROME */}
        {/**FOR CHROME */}
        <CustomView condition={browserName === "Chrome"}>
          <div className="m-container chrome" id="mContain">
            <Background />

            {/**SLIDER */}
            {/**SLIDER */}
            {/**SLIDER */}
            <div className="m-indeSlider chrome">
              <div className="m-isContainer" id="slider">
                {/**PAGE */}
                <div className="m-isPage">
                  <Mprofile
                    page={this.state.page}
                    axios={this.props.axios}
                    logIn={this.props.logIn}
                    isLogin={this.props.isLogin}
                    setUserInfo={this.props.setUserInfo}
                  />
                </div>
                {/**PAGE */}

                {/**PAGE */}
                <div className="m-isPage">
                  <Mhome axios={this.props.axios} logIn={this.props.logIn} />
                </div>
                {/**PAGE */}

                {/**PAGE */}
                <div className="m-isPage">
                  <MallNames
                    page={this.state.page}
                    axios={this.props.axios}
                    logIn={this.props.logIn}
                  />
                </div>
                {/**PAGE */}
              </div>
            </div>
            {/**SLIDER */}
            {/**SLIDER */}
            {/**SLIDER */}

            <Mfooter
              changePage={this.changePage}
              page={this.state.page}
              axios={this.props.axios}
              logIn={this.props.logIn}
            />
          </div>
        </CustomView>
      </>
    );
  }
}

export default Mindex;
