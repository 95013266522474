import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import NameCard from "../namecard/namecard";
import "./wishlist.css";
import { toast } from "react-toastify";

import Msg from "../msg/msg";

class Wishlist extends Component {
  constructor() {
    super();

    this.state = {
      Names: [
        // {userId:"jkashdckjs",nameId:{
        //   _id:"hjafbsfbshjdbfhkjs",
        //   name:"aman",
        //   meaning:"kjahfs",
        //   rel:"akjsbnjk",
        //   sex:"kjsahcf"
        // }}
      ],

      msg: ""
    };
  }
  componentDidMount() {
    setTimeout(() => this.showItems(), 500);
    this.loadWishList();

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }
  componentDidUpdate() {
    this.props.HomePage && setTimeout(() => this.hideWishListItems(), 100);
    console.log(this.props.HomePage);

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  showItems = () => {
    document.getElementById("wishlist").classList.add("start");
  };

  hideWishListItems = () => {
    document.getElementById("wishlist").classList.remove("start");
  };
  loadWishList = () => {
    this.props.axios
      .get("/loadWishList")
      .then(resp => {
        if (resp.data.status) {
          this.setState({ Names: resp.data.results });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };
  removeFromWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ Names }) => {
              var nl = Names.filter((val, i) => {
                if (val.nameId._id != id) {
                  return val;
                }
              });
              return { Names: nl };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({
        msg: "You Need To Login to remove a Name from wishList"
      });
      //toast.error("You Need To Login to remove a Name from wishList");
    }
  };
  render() {
    return (
      <>
        {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}  msg={this.state.msg} />}
        <div
          className={this.props.mobile ? "WishList mob" : "WishList"}
          id="wishlist"
        >
          <div
            className={
              this.props.mobile ? "wishlistContainer mob" : "wishlistContainer"
            }
          >
            <div className="wlcTop">
              {!this.props.mobile && (
                <div className="wlcBackBTn" onClick={this.props.hideWishList}>
                  <div className="wlcBackInc" />
                </div>
              )}
            </div>
            <div className="wlcCenter">
              <div className="wlcCenterContainer">
                <Scrollbars
                  style={{ width: "100%", height: "100%" }}
                  renderTrackVertical={props => (
                    <div {...props} className="track-vertical" />
                  )}
                  renderThumbVertical={props => (
                    <div {...props} className="thumb-vertical" />
                  )}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  universal={true}
                >
                  <div className="WishListNameContainer">
                    {this.state.Names.map(Names => (
                      <div
                        className={
                          this.props.mobile
                            ? "WishListNameCard mob"
                            : "WishListNameCard"
                        }
                        key={Names.nameId._id}
                      >
                        {/**CARD */}
                        {/**CARD */}

                        <NameCard
                          id={Names.nameId._id}
                          name={Names.nameId.name}
                          gender={Names.nameId.sex}
                          des={Names.nameId.meaning}
                          removeFromWishList={this.removeFromWishList}
                          wishlist={true}
                        />

                        {/**CARD */}
                        {/**CARD */}
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </div>
            </div>
            <div className="wlcbottom">
              <div className="sortByBtn">
                <p>A-Z</p>
              </div>
              <div className="sortByBtn">
                <p>Last Added</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Wishlist;
