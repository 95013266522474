import React, { Component } from "react";
import "./load.css";

class Square extends Component {
  render() {
    return (
      <>
        <div className="Load">
          <div className="squareLoad" />
        </div>
      </>
    );
  }
}

export default Square;
