import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./menu.css";

class Menu extends Component {
  state = {};
  componentDidMount() {
    setTimeout(() => this.showMenu(), 100);
  }

  showMenu = () => {
    document.getElementById("menuOverlay").classList.remove("close");
    document.getElementById("menuOverlayTwo").classList.remove("close");

    setTimeout(
      () => document.getElementById("menu").classList.remove("close"),
      500
    );
    setTimeout(
      () => document.getElementById("CloseLine1").classList.add("open"),
      500
    );
    setTimeout(
      () => document.getElementById("CloseLine2").classList.add("open"),
      500
    );
  };

  hidemenu = () => {
    document.getElementById("CloseLine1").classList.remove("open");
    document.getElementById("CloseLine2").classList.remove("open");
    setTimeout(
      () => document.getElementById("menu").classList.add("close"),
      500
    );

    setTimeout(
      () => document.getElementById("menuOverlay").classList.add("close"),
      1000
    );
    setTimeout(
      () => document.getElementById("menuOverlayTwo").classList.add("close"),
      1000
    );
    setTimeout(() => this.props.hideMenu(), 1600);
  };
  render() {
    return (
      <>
        <div className="FullScreenMenu close" id="menuOverlay">
          <div className="FullScreenMenuContainer close" id="menuOverlayTwo">
            <div className="menuContainer close" id="menu">
              {/**CLOSE BTN */}
              {/**CLOSE BTN */}
              <div className="closeBtn">
                <div className="CloseBtnLines" onClick={this.hidemenu}>
                  <div className="CloseBtnLine" id="CloseLine1" />
                  <div className="CloseBtnLine" id="CloseLine2" />
                </div>
              </div>
              {/**CLOSE BTN */}
              {/**CLOSE BTN */}

              {/**MENU */}
              {/**MENU */}
              <div className="menuList">
                <div className="menuListItems">
                  <p>About</p>
                  <div className="menuItemHoverLine" />
                </div>
                {!this.props.pro && (
                  <div className="menuListItems upgrade">
                    <p>Upgrade</p>
                    <div className="menuItemHoverLine" />
                  </div>
                )}

                <div className="menuListItems">
                  <p>Patch Note</p>
                  <div className="menuItemHoverLine" />
                </div>
                <div className="menuListItems">
                  <p>Careers</p>
                  <div className="menuItemHoverLine" />
                </div>
                <div className="menuListItems">
                  <p>Contact Us</p>
                  <div className="menuItemHoverLine" />
                </div>
              </div>
              {/**MENU */}
              {/**MENU */}

              {/**SOCIAL LINKS */}
              {/**SOCIAL LINKS */}
              <div className="socialLinks">
                <div className="fb" />
                <div className="Insta" />
                <div className="Tweet" />
                <div className="Git" />
              </div>
              {/**SOCIAL LINKS */}
              {/**SOCIAL LINKS */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Menu;
