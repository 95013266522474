import React, { Component } from "react";
import Square from "../loading/load";
import "./namecard.css";

class LoadCard extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="loadCard">
          <div className="nameCardTitle">
            <Square />
          </div>

          <div className="nameGender">
            <Square />
          </div>

          <div className="nameDes">
            <Square />
          </div>

          <div className="nameWishList" />
        </div>
      </>
    );
  }
}

export default LoadCard;
