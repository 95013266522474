import React, { Component } from "react";
import { Route } from "react-router-dom";
import Square from "../loading/load";
import NameCard from "../namecard/namecard";
import LoadCard from "../namecard/nameLoad";
import Msg from "../msg/msg";
import { Scrollbars } from "react-custom-scrollbars";
import "./hp.css";
import TTS from '../../tts';
// import { toast } from "react-toastify";
import ScrollToFetch from "react-scroll-to-fetch";
// import { api } from "../../conf";
// import { thisExpression } from "@babel/types";
//var spinner = require("../../images/spinner.gif");
class HomePage extends Component {
  constructor() {
    super();

    this.state = {
      nameId: "",
      val: "",
      searchGender: "",
      religion: "No Religion",
      gender: "Gender",
      rel: "All",
      loadingRandomBtn: false,
      hasMore: true,
      religions: [
        { id: 1, religion: "All Religion" },
        // { id: 2, religion: "Christianity" },
        { id: 3, religion: "Islam" },
        { id: 4, religion: "Hinduism" }
        // { id: 5, religion: "Buddhism" },
        // { id: 6, religion: "Sikhism" },
        // { id: 7, religion: "Judaism" }
      ],
      inWishList: false,
      suggestions: [],
      name: "A Name",
      des: "The Meaning",
      speak: false,
      loaded: true,
      msg: "",
      speak:false
    };
  }

  componentDidMount() {
    /**STARTING ANIMATION */
    this.startingAnimation();
    this.todaysPic();

    /**STARTING ANIMATION */

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  addToWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/addWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState({ inWishList: true });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  addToWishListSug = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/addWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ suggestions }) => {
              var newList = suggestions.map((v, i) => {
                if (v._id === id) {
                  v.wishList = true;
                  return v;
                }
                return v;
              });
              return { suggestions: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  removeFromWishListSug = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState(({ suggestions }) => {
              var newList = suggestions.map((v, i) => {
                if (v._id === id) {
                  v.wishList = false;
                  return v;
                }
                return v;
              });
              return { suggestions: newList };
            });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({ msg: "You Need To Login to add a Name to wishList" });
      //toast.error("You Need To Login to add a Name to wishList");
    }
  };
  removeFromWishList = id => {
    if (this.props.logIn) {
      this.props.axios
        .post("/removeWishList", { id })
        .then(resp => {
          if (resp.data.status) {
            this.setState({ msg: resp.data.msg });
            //toast.success(resp.data.msg);
            this.setState({ inWishList: false });
          } else {
            this.setState({ msg: resp.data.msg });
            //toast.error(resp.data.msg);
          }
        })
        .catch(err => {
          this.setState({ msg: err.message });
          //toast.error(err.message);
        });
    } else {
      this.setState({
        msg: "You Need To Login to remove a Name from wishList"
      });
      //toast.error("You Need To Login to remove a Name from wishList");
    }
  };

  componentDidUpdate() {
    /**STARTING ANIMATION */
    try{
      this.state.speak &&
      TTS.speak(
        this.state.name,
        () => {
          // console.log("Speaking: " + props.name + "");
        },
        e => {
          this.setState({ speak: false });
        }
      );
    }catch(err){

    }
    

    !this.state.speak && TTS.cancel();
    this.state.val.trim().length < 1 && setTimeout(this.addingClass(), 100);

    !this.props.HomePage && setTimeout(() => this.hideHomePageItems(), 100);
    !this.props.HomePage && setTimeout(() => this.props.showWishList(), 1100);

    /**STARTING ANIMATION */

    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  startingAnimation = () => {
    const item1 = document.getElementById("item1");
    const item2 = document.getElementById("item2");
    const lastSec = document.getElementById("lastSec");
    const main = document.getElementById("main");

    setTimeout(() => item1.classList.add("hpItemsStart"), 100);
    setTimeout(() => item2.classList.add("hpItemsStart"), 300);
    setTimeout(() => lastSec.classList.add("hpItemsStart"), 500);
    setTimeout(() => main.classList.add("start"), 800);
  };
  addingClass = () => {
    const item1 = document.getElementById("item1");
    const item2 = document.getElementById("item2");
    const lastSec = document.getElementById("lastSec");

    item1.classList.add("hpItemsStart");
    setTimeout(() => item2.classList.add("hpItemsStart"), 100);
    setTimeout(() => lastSec.classList.add("hpItemsStart"), 200);
  };

  hideHomePageItems = () => {
    const item1 = document.getElementById("item1");
    const item2 = document.getElementById("item2");
    const lastSec = document.getElementById("lastSec");
    const main = document.getElementById("main");

    setTimeout(() => main.classList.remove("start"), 100);
    setTimeout(() => lastSec.classList.remove("hpItemsStart"), 200);
    setTimeout(() => item2.classList.remove("hpItemsStart"), 350);
    setTimeout(() => item1.classList.remove("hpItemsStart"), 500);
  };

  handleSearch = val => {
    this.setState(
      { val: val.target.value, hasMore: true, suggestions: [] },
      () => {
        this.search(1);
      }
    );
  };

  searchOnFocus = () => {
    this.state.val.trim().length < 1 &&
      document.getElementById("SearchBar").classList.toggle("focusOut");
  };

  getMSeachIcon = () => {
    let classes = "hpsearchIcn ";
    this.state.val.trim().length >= 1 && (classes = "hpsearchIcnClose");
    return classes;
  };
  getSpeakerIcnClass = () => {
    let classes = "TpSpeakIcn";
    this.state.speak && (classes = "TpSpeakStop");
    return classes;
  };
  getsearchbarClass = () => {
    let classes = "hpItems ";
    classes += this.state.val.trim().length >= 1 && "hpItemsStart searchShow";
    classes += this.state.val.trim().length < 1 && "hpItemsStart";
    return classes;
  };

  handleClose = () => {
    this.state.val.trim().length >= 1 &&
      this.setState({
        val: ""
      });
    this.state.val.trim().length >= 1 &&
      document.getElementById("SearchBar").classList.toggle("focusOut");
  };

  HandleGenderClick = gender => {
    if (gender === "boy") {
      document.getElementById("boy").classList.toggle("selected");
      this.state.boy
        ? this.setState({ boy: false })
        : this.setState({ boy: true });
    }

    if (gender === "girl") {
      document.getElementById("girl").classList.toggle("selected");
      this.state.girl
        ? this.setState({ girl: false })
        : this.setState({ girl: true });
    }
  };

  dropDownToggle = () => {
    document.getElementById("dropDownIcn").classList.toggle("select");
    document.getElementById("dropdownMenu").classList.toggle("hide");
    document.getElementById("lastSec").classList.toggle("opacity");
  };

  changeReligion = selected => {
    this.setState({
      religion: selected
    });
  };

  randomBtnHandler = () => {
    const { axios } = this.props;
    const { boy, girl, religion } = this.state;
    document.getElementById("tpLabel").classList.add("fade");
    this.setState({ loaded: false, loadingRandomBtn: true });
    axios
      .post("/random", {
        boy: boy,
        girl: girl,
        religion
      })
      .then(resp => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        if (resp.data.status) {
          this.setState({
            nameId: resp.data.result._id,
            name: resp.data.result.name,
            des: resp.data.result.meaning,
            rel: resp.data.result.rel,
            gender: resp.data.result.sex,
            inWishList: resp.data.WishList
          });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        // console.log(err);
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };

  todaysPic = () => {
    const { axios } = this.props;
    // document.getElementById("tpLabel").classList.add("fade");
    this.setState({ loaded: false, loadingRandomBtn: true });
    axios
      .get("/todaysPick")
      .then(resp => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        if (resp.data.status) {
          this.setState({
            nameId: resp.data.result.nameId._id,
            name: resp.data.result.nameId.name,
            des: resp.data.result.nameId.meaning,
            rel: resp.data.result.nameId.rel,
            gender: resp.data.result.nameId.sex,
            inWishList: resp.data.WishList
          });
        } else {
          this.setState({ msg: resp.data.msg });
          //toast.error(resp.data.msg);
        }
      })
      .catch(err => {
        this.setState({ loaded: true, loadingRandomBtn: false });
        // console.log(err);
        this.setState({ msg: err.message });
        //toast.error(err.message);
      });
  };
  searchCond = sex => {
    this.setState({ hasMore: true, suggestions: [], searchGender: sex }, () => {
      this.search(1);
    });
  };

  search = async page => {
    const query = this.state.val;
    const gender = this.state.searchGender;
    try {
      const resp = await this.props.axios.post("/search", {
        query,
        page,
        gender
      });
      if (resp.data.status) {
        this.setState(state => {
          var suggestions = [...state.suggestions, ...resp.data.list];
          return { suggestions };
        });

        if (resp.data.list.length < 20) {
          this.setState({ hasMore: false });
        }
      } else {
        this.setState({ hasMore: false });
      }
    } catch (err) {
      this.setState({ msg: err.message });
      //toast.error(err.message);
    }
  };
  speak = () => {
    this.setState({ speak: !this.state.speak });
  };
  render() {
    return (
      <>
        {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}  onClick={()=>this.setState({msg:""})} msg={this.state.msg} />}
        <div className="Homepage" id="main">
          <div className={this.getsearchbarClass()} id="item1">
            <div className="hpSearchSec">
              <div className="searchLabel focusOut" id="SearchBar">
                <p>Search a name</p>
              </div>

              <div className="HpSearchBar">
                <input
                  type="text"
                  onFocus={this.searchOnFocus}
                  onBlur={this.searchOnFocus}
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  onChange={this.handleSearch}
                  value={this.state.val}
                />
                <div
                  className={this.getMSeachIcon()}
                  id="searchIcn"
                  onClick={this.handleClose}
                />
              </div>
              <div className="HpSearchwhiteLine" />
            </div>
          </div>

          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {this.state.val.trim().length >= 1 && (
            <>
              <div className="SearchResultsHome">
                <div className="SearchResultsHomeContainer">
                  <div className="srhFilters">
                    <p
                      onClick={() => this.searchCond("")}
                      className={
                        this.state.searchGender === "" ? "s_active" : ""
                      }
                    >
                      Search all name with "{this.state.val}"
                    </p>
                    {this.state.val.trim().length >= 2 && (
                      <>
                        <p
                          onClick={() => this.searchCond("Boy")}
                          className={
                            this.state.searchGender === "Boy" ? "s_active" : ""
                          }
                        >
                          Search all boy's name with "{this.state.val}"
                        </p>
                        <p
                          onClick={() => this.searchCond("Girl")}
                          className={
                            this.state.searchGender === "Girl" ? "s_active" : ""
                          }
                        >
                          Search all girl's name with "{this.state.val}"
                        </p>
                      </>
                    )}
                  </div>

                  <div className="srhSuggestions">
                    <div className="srhSuggContainer">
                      <Scrollbars
                        style={{ width: "100%", height: "100%" }}
                        renderTrackVertical={props => (
                          <div {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={props => (
                          <div {...props} className="thumb-vertical" />
                        )}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        universal={true}
                        id="search_results"
                      >
                        {/**SUGGESTIONS */}
                        {/**SUGGESTIONS */}
                        {/**SUGGESTIONS */}
                        <ScrollToFetch
                          loader={
                            <div className="nameCardContainer">
                              <LoadCard />
                            </div>
                          }
                          fetch={this.search}
                          finished={!this.state.hasMore}
                          className="srchContainerSuggest"
                          scrollParent="search_results"
                          initialLoad={false}
                          successMessage={
                            this.state.suggestions.length === 0 ? (
                              <p className="no_result">No results found</p>
                            ) : (
                              <p> </p>
                            )
                          }
                        >
                          {this.state.suggestions.map(suggest => (
                            <div
                              className="nameCardContainer"
                              key={suggest._id}
                            >
                              {/**CARD */}
                              {/**CARD */}

                              <NameCard
                                id={suggest._id}
                                name={suggest.name}
                                gender={suggest.sex}
                                des={suggest.meaning}
                                addToWishList={this.addToWishListSug}
                                removeFromWishList={this.removeFromWishListSug}
                                wishlist={suggest.wishList}
                                rel={suggest.rel}
                              />

                              {/**CARD */}
                              {/**CARD */}
                            </div>
                          ))}
                        </ScrollToFetch>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}
          {/**SEARCH RESULTS */}

          {this.state.val.trim().length < 1 && (
            <>
              <div className="hpItems" id="item2">
                <div className="hpRandomBtns">
                  {/**GENDER */}
                  {/**GENDER */}
                  {/**GENDER */}
                  <div className="gender">
                    <div
                      className="genderBtn"
                      id="boy"
                      onClick={() => this.HandleGenderClick("boy")}
                    >
                      <p>Boy</p>
                    </div>
                    <div
                      className="genderBtn"
                      id="girl"
                      onClick={() => this.HandleGenderClick("girl")}
                    >
                      <p>Girl</p>
                    </div>
                  </div>
                  {/**GENDER */}
                  {/**GENDER */}
                  {/**GENDER */}

                  {/**RELIGION */}
                  {/**RELIGION */}
                  {/**RELIGION */}

                  <div className="religion">
                    <div className="religionTab" onClick={this.dropDownToggle}>
                      <div className="selectionReligion">
                        <p>{this.state.religion}</p>
                        <div className="dropdownIcn" id="dropDownIcn" />
                      </div>
                      <div className="religionDropDown  hide" id="dropdownMenu">
                        <Scrollbars
                          style={{ width: "100%", height: "100%" }}
                          renderTrackVertical={props => (
                            <div {...props} className="track-vertical" />
                          )}
                          renderThumbVertical={props => (
                            <div {...props} className="thumb-vertical" />
                          )}
                          autoHide
                          autoHideTimeout={1000}
                          autoHideDuration={200}
                          universal={true}
                        >
                          <div className="rgDropDownContainer">
                            {this.state.religions.map(religion => (
                              <div
                                key={religion.id}
                                className="DDItem"
                                id={religion.id}
                                onClick={() =>
                                  this.changeReligion(religion.religion)
                                }
                              >
                                <p>{religion.religion}</p>
                              </div>
                            ))}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>
                  </div>

                  {/**RELIGION */}
                  {/**RELIGION */}
                  {/**RELIGION */}

                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                  <div className="randomBtn">
                    <p onClick={this.randomBtnHandler}>
                      {this.state.loadingRandomBtn ? "..." : "Random"}
                    </p>
                  </div>
                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                  {/**RANDOM BTN */}
                </div>
              </div>

              <div className="hpItems" id="lastSec">
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                <div className="TodaysPick">
                  <p id="tpLabel">today's pick</p>

                  {/**NAMECARD */}
                  {/**NAMECARD */}
                  {this.state.loaded && (
                    <>
                      <div className="tpCardContainer">
                        <div className="tpCard">
                          <div className="tpName">
                            <p>{this.state.name}</p>
                            <div className={this.getSpeakerIcnClass()} onClick={this.speak} />
                          </div>
                          <div className="nameGender">
                            <p>
                              <span>Gender :</span> {this.state.gender}
                            </p>
                          </div>
                          <div className="tpDes">
                            <p>{this.state.des}</p>
                          </div>
                          {this.state.inWishList ? (
                            <div
                              className="tpWishList"
                              onClick={() =>
                                this.removeFromWishList(this.state.nameId)
                              }
                            >
                              <div className="wishlstBTn">
                                <p>-</p>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="tpWishList"
                              onClick={() =>
                                this.addToWishList(this.state.nameId)
                              }
                            >
                              <div className="wishlstBTn">
                                <p>+</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {/**LOADING */}
                  {/**LOADING */}
                  {!this.state.loaded && (
                    <>
                      <div className="tpCardContainer">
                        <div className="tpCardLoad">
                          <div className="tpName">
                            <Square />
                          </div>

                          <div className="tpDes">
                            <Square />
                          </div>

                          <div className="tpWishList" />
                        </div>
                      </div>
                    </>
                  )}

                  {/**LOADING */}
                  {/**LOADING */}
                  {/**LOADING */}
                </div>
                {/**NAMECARD */}
                {/**NAMECARD */}

                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
                {/**TODAYS PICK */}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default HomePage;
