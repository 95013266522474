import React, { Component } from "react";
import "./index.css";
import Background from "../bg/bg";
import HomePage from "../HomePage/hp";
import Side from "../side/side";
import Allnames from "../allnames/allnames";
import Wishlist from "../wishlist/wishList";

class Index extends Component {
  constructor() {
    super();

    this.state = {
      page: 1,
      wishList: false,
      HomePage: true
    };
  }

  componentDidMount() {
    document.title = "nextname";
  }

  componentDidUpdate() {
    this.state.page === 1
      ? !this.state.HomePage
        ? (document.title = "My Wishlist")
        : (document.title = "nextname")
      : (document.title = "allnames");

    this.state.page === 2 &&
      document.getElementById("indexContainer").classList.add("page2");
    this.state.page === 1 &&
      document.getElementById("indexContainer").classList.remove("page2");
  }

  asyncSetState = obj => {
    return new Promise(resolve => {
      this.setState(obj, () => {
        resolve();
      });
    });
  };

  hideHomePage = async () => {
    await this.setState({ HomePage: false });
    await setTimeout(() => this.setState({ wishList: true }), 1500);
  };
  showWishList = async () => {
    await this.setState({ wishList: true });
  };

  showHomePage = async () => {
    await this.setState({ HomePage: true });
    await this.setState({ wishList: false });
  };
  hideWishList = async () => {
    await this.setState({ HomePage: true });
    await setTimeout(() => this.setState({ wishList: false }), 500);
  };

  pageChange = () => {
    this.setState({ page: 2 });
  };
  pageChange2 = () => {
    this.setState({ page: 1 });
  };

  render() {
    return (
      <>
        <div className="body">
          <Background />
        </div>
        <div className="indexContainer" id="indexContainer">
          <div className="icItemOne">
            {!this.state.wishList && (
              <HomePage
                HomePage={this.state.HomePage}
                showWishList={this.showWishList}
                axios={this.props.axios}
                logIn={this.props.logIn}
              />
            )}
            {this.state.wishList && (
              <Wishlist
                wishList={this.state.wishList}
                HomePage={this.state.HomePage}
                showHomePage={this.showHomePage}
                hideWishList={this.hideWishList}
                axios={this.props.axios}
                logIn={this.props.logIn}
              />
            )}
          </div>

          <div className="icItemTwo">
            <Side
              page={this.state.page}
              logIn={this.props.logIn}
              pro={this.props.pro}
              userInfo={this.props.userInfo}
              pageChange={this.pageChange}
              hideHomePage={this.hideHomePage}
              hideWishList={this.hideWishList}
              wishList={this.state.wishList}
              HomePage={this.state.HomePage}
            />
          </div>

          <div className="icItemThree">
            <Allnames
              page={this.state.page}
              logIn={this.props.logIn}
              pro={this.props.pro}
              userInfo={this.props.userInfo}
              pageChange2={this.pageChange2}
              axios={this.props.axios}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Index;
