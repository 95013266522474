import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "./namecard.css";
import TTS from "../../tts";
import Msg from "../msg/msg";

class NameCard extends Component {
  state = {
    speak: false,
    msg:""
  };

  componentDidUpdate(props) {
    try {
      this.state.speak &&
      TTS.speak(
        props.name,
        () => {
          // console.log("Speaking: " + props.name + "");
        },
        e => {
          this.setState({ speak: false });
        }
      );
    }catch(err){
      // console.log(err)
      this.setState({msg:err.message,speak:false});
    }
    
    try{
      !this.state.speak && TTS.cancel();
    }catch(err){

    }
    // let foo = new p5.Speech(); // speech synthesis object
    // this.state.speak && foo.speak("hi there"); // say something
    this.state.msg.length > 0 &&
      setTimeout(() => this.setState({ msg: "" }), 3000);
  }

  speak = () => {
    this.setState({ speak: !this.state.speak });
  };

  getSpeakerIcnClass = () => {
    let classes = "SpeakIcn";
    this.state.speak && (classes = "SpeakStop");
    return classes;
  };
  render() {
    return (
      <>
        {this.state.msg.length > 0 && <Msg  onClick={()=>this.setState({msg:""})}   onClick={()=>this.setState({msg:""})} msg={this.state.msg}/>}
        <div className="nameCard">
          <div className="nameCardTitle">
            <p>{this.props.name}</p>
            <div className={this.getSpeakerIcnClass()} onClick={this.speak} />
          </div>

          <div className="nameGender">
            <p>
              <span>Gender:</span> {this.props.gender}
            </p>
            {this.props.religion && (
              <p>
                <span>Religion :</span> {this.props.rel}
              </p>
            )}
          </div>
          <br />
          <div className="nameDes">
            <Scrollbars
              ref="scrollbars"
              style={{ width: "100%", height: "101%" }}
              renderTrackVertical={props => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbVertical={props => (
                <div {...props} className="thumb-vertical" />
              )}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              universal={true}
              id="allnames_scroll"
            >
              <p>{this.props.des}</p>
            </Scrollbars>
          </div>

          {this.props.wishlist ? (
            <div
              className="nameWishList"
              onClick={() => this.props.removeFromWishList(this.props.id)}
            >
              <div className="wishlstBTn">
                <p>-</p>
              </div>
            </div>
          ) : (
            <div
              className="nameWishList"
              onClick={() => this.props.addToWishList(this.props.id)}
            >
              <div className="wishlstBTn">
                <p>+</p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default NameCard;
