import React, { Component } from "react";
import "./msg.css";

class Msg extends Component {
  // componentDidMount() {
  //   setTimeout(
  //     () => document.getElementById("msgBox").classList.add("fade"),
  //     2000
  //   );
  //   setTimeout(
  //     () => document.getElementById("msgBox").classList.add("hide"),
  //     3000
  //   );
  // }
  render() {
    return (
      <>
        <div className="msgBoxContainer" id="msgBox" onClick={this.props.onClick}>
          <div className="msgBox">
            <p>{this.props.msg}</p>
          </div>
        </div>
      </>
    );
  }
}

export default Msg;
