var synth = window.speechSynthesis;
var TTS = {
  speak: (text, onStart = () => {}, onEnd = e => {}) => {
    if(synth){
    if (synth.speaking) {
      throw new Error("Already Speaking");
    } else {
      onStart();
      var list = synth.getVoices();
      //console.log(list);
      var utterThis = new SpeechSynthesisUtterance(text);
      for (var i = 0; i < list.length; i++) {
        if (list[i].name === "Hindi" || list[i].name === "Google हिन्दी") {
          utterThis.voice = list[i];
          // console.log("hindi found")
          break;
        }
      }
      // utterThis.voice=list[7];
      synth.speak(utterThis);
      utterThis.onend = onEnd;
    }
  }else{
    throw new Error("TTS is not Supported in this browser")
  }
  },
  cancel: () => {
    if(synth){
      synth.cancel();
    }else{
      throw new Error("TTS not Supported in this browser")
    }
    
  }
};

export default TTS;
