import React, { Component } from "react";
import "./bg.css";

class Background extends Component {
  state = {};
  render() {
    return <div className="bg" />;
  }
}

export default Background;
