import React, { Component } from "react";
import "./m-footer.css";

const Mfooter = props => {
  return (
    <div className="m-footer">
      <div
        className={
          props.page === 1 ? "m-footerItems selected" : "m-footerItems"
        }
        onClick={() => props.changePage(1)}
      >
        <div
          className={props.logIn ? "m-footerIcn wish" : "m-footerIcn profile"}
        />
        {props.logIn ? <p>Wishlist</p> : <p>Log In</p>}
      </div>

      <div
        className={
          props.page === 2 ? "m-footerItems selected" : "m-footerItems"
        }
        onClick={() => props.changePage(2)}
      >
        <div className="m-footerIcn home" />
        <p>Home</p>
      </div>

      <div
        className={
          props.page === 3 ? "m-footerItems selected" : "m-footerItems"
        }
        onClick={() => props.changePage(3)}
      >
        <div className="m-footerIcn allNames" />
        <p>All Names</p>
      </div>
    </div>
  );
};

export default Mfooter;
